import React, { useState, useRef } from "react";
import { Decal, useGLTF, Html } from "@react-three/drei";
import { useDispatch, useSelector } from 'react-redux';
import { selectDecal } from "../redux/slices/decalSlice";
import ProductDecal from "./ProductDecal";

function ProductModel(props) {
  const dispatch = useDispatch();
  const textureRef = useRef();
  const { nodes, setNodes } = useGLTF(props.src);
  const [position, setPosition] = useState([7, 5, -150]);
  const [decalCanvas, setDecalCanvas] = useState(null)
  const cropUpdate = useSelector((state) => state.decalsData.cropUpdate)
  const decalkeys = useSelector((state) => Object.keys(state.decalsData.decals));

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.model.geometry}
        material={nodes.model.material}
        rotation={[Math.PI / 2, 0, 0]}
      >
        <meshStandardMaterial polygonOffset={true} />
        {decalkeys.length > 0 && decalkeys.map((key) => {
          return (
            <ProductDecal
              key={key}
              decalID={key}
            >
            </ProductDecal>)

        })
        }
      </mesh>
    </group>
  );
}


export default ProductModel;