import { configureStore } from '@reduxjs/toolkit';
import decalsReducer from './slices/decalSlice';
import productsReducer from './slices/productsSlice';

export const store = configureStore({
    reducer: {
        decalsData: decalsReducer,
        productsData: productsReducer
    },
    devTools: (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
});