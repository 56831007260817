import Viewer from './views/Viewer';
import './App.css';
import { Suspense } from 'react';
import Editor from './views/Editor';

function App() {
  return (
    <Suspense>
      <div className='sb-configurator__inner-container'>
        <Viewer></Viewer>
        <Editor></Editor>
      </div>
    </Suspense>
  );
}

export default App;
