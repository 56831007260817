import React, { useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { loadDecalFile, cropUpdate } from '../redux/slices/decalSlice';
import Cropper from "react-cropper";
import "../styles/cropper.css";

function DecalEditor() {
    const cropperHeight = 400;
    const cropperWidth = "100%";
    const cropperRef = useRef(); // reference to the cropper el
    const selectedDecal = useSelector((state) => state.decalsData.decals[state.decalsData.selectedDecal]);
    const selectedDecalKey = useSelector((state) => state.decalsData.selectedDecal);
    const dispatch = useDispatch();

    /**
     * Rotate the cropper image
     * @param {*} goLeft direction of rotation
     */
    function rotateCropper(goLeft) {
        if (goLeft) {
            cropperRef.current.cropper.rotate(-45);
        } else {
            cropperRef.current.cropper.rotate(45);
        }
        onCropperUpdate();
    }

    function onCropperUpdate() {
        dispatch(cropUpdate({
            decalID: selectedDecalKey,
            crop: cropperRef.current.cropper.getData(),
        }))
    }


    return (
        <div className="sb-decal-editor">
            <h1>Selected decal: {selectedDecalKey}</h1>
            {selectedDecal && (<><input type="file" accept="image/*" onChange={(e) => { dispatch(loadDecalFile({ decalID: selectedDecalKey, file: e.target.files.item(0) })) }} />
                <button onClick={() => { rotateCropper(true) }}>
                    Rotate Left
                </button>
                <button onClick={() => { rotateCropper(false) }}>
                    Rotate Right
                </button>
                {
                    selectedDecal.imgDataURI && (
                        <div id={`sb-decal-editor__cropper--${selectedDecalKey}`}>
                            < Cropper
                                viewMode={1}
                                ref={cropperRef}
                                rotatable={true}
                                ready={onCropperUpdate}
                                crop={onCropperUpdate}
                                src={selectedDecal.imgDataURI}
                                style={{ height: cropperHeight, width: cropperWidth }}
                                aspectRatio={1}
                                guides={true}
                            >
                            </Cropper>
                        </div>)
                }
            </>)
            }
        </div >
    )
}


export default DecalEditor;