import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    selectedDecal: null,
    decals: {
        "bottom": {
            position: [0, 0.12, -1.2],
            rotation: [0.5 * Math.PI, Math.PI, Math.PI],
            scale: 0.1,
            crop: null
        },
        "neck": {
            position: [0, 0.1, -1.4],
            rotation: [0.3 * Math.PI, Math.PI, Math.PI],
            scale: 0.1,
            crop: null
        }
    }
}

/**
 * Loads image to edit from file
 */
export const loadDecalFile = createAsyncThunk(
    'imageEdit/loadDecalFileStatus',
    async (payload, thunkAPI) => {
        // Check if there is a file
        if (payload.file) {
            // Load file and convert to image string
            return await new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.addEventListener('load', () =>
                    resolve({ decalID: payload.decalID, imgDataURI: reader.result.toString() || '' }),
                )
                reader.readAsDataURL(payload.file);
            })
        } else {
            return { success: false };
        }

    }
)

/**
 * Slice for image editing related 
 */
export const decalSlice = createSlice({
    name: "decalsData",
    initialState,
    reducers: {
        // Update decal saved crop state
        cropUpdate: (state, action) => {
            state.decals[action.payload.decalID].croppedImgDataURI = action.payload.croppedImgDataURI;
            state.decals[action.payload.decalID].crop = action.payload.crop;
        },
        selectDecal: (state, action) => {
            state.selectedDecal = action.payload.decalID;
        }
    },

    extraReducers: (builder) => {
        builder.addCase(loadDecalFile.fulfilled, (state, action) => {
            state.decals[action.payload.decalID].imgDataURI = action.payload.imgDataURI;
        })
    }
})

export const { cropUpdate, selectDecal } = decalSlice.actions;
export default decalSlice.reducer;


