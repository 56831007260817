import React, { useState, useRef, useEffect } from "react";
import { Decal, useGLTF, Html } from "@react-three/drei";
import { useDispatch, useSelector } from 'react-redux';
import { selectDecal } from "../redux/slices/decalSlice";

function ProductDecal(props) {
    const textureRef = useRef();
    const [decalCanvas, setDecalCanvas] = useState();
    const dispatch = useDispatch();
    const decal = useSelector((state) => state.decalsData.decals[props.decalID]);
    const crop = useSelector((state) => state.decalsData.decals);


    // Listen for crop updates
    useEffect(function (state) {
        const cropperEl = document.querySelector(`#sb-decal-editor__cropper--${props.decalID} .cropper-hidden`);
        if (cropperEl) {
            const canvas = cropperEl.cropper.getCroppedCanvas({
                width: 1200,
                height: 1200,
            });

            setDecalCanvas(canvas)
            if (textureRef.current) {
                textureRef.current.needsUpdate = true;
            }
        }
    }.bind(this), [crop]);


    return (
        <Decal
            key={props.key}
            debug={(!process.env.NODE_ENV || process.env.NODE_ENV === 'development')} // Makes "bounding box" of the decal visible
            position={decal.position} // Position of the decal
            rotation={decal.rotation} // Rotation of the decal (can be a vector or a degree in radians)
            scale={decal.scale} // Scale of the decal
            depthTest={false}
        >
            <Html
                position={decal.position}
                as='div' // Wrapping element (default: 'div')
                wrapperClass={'sb-product-model__decal-tooltip'} // The className of the wrapping element (default: undefined)
                center // Adds a -50%/-50% css transform (default: false) [ignored in transform mode]
                zIndexRange={[100, 0]} // Z-order range (default=[16777271, 0])
            >
                <div className='sb-decal-tooltip__inner-container' onClick={() => { dispatch(selectDecal({ decalID: props.decalID })) }}></div>
            </Html>
            <meshStandardMaterial
                roughness={1}
                transparent
                depthTest={false}
                alphaTest={0}
                polygonOffset={true}
                polygonOffsetFactor={-10} >
                <canvasTexture
                    ref={textureRef}
                    attach="map"
                    image={decalCanvas}
                    needsUpdate={true}
                />
            </meshStandardMaterial>
        </Decal>
    );
}


export default ProductDecal;