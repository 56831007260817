import React, { useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import { Backdrop, Center, Decal, FlyControls, OrbitControls, PivotControls, Plane } from '@react-three/drei';
import { useSelector } from 'react-redux';
import ProductModel from '../components/productModel';

function Viewer(props) {
    const canvasRef = useRef();
    const models = useSelector((state) => state.productsData.models)

    /**
     * Screenshot and save the canvas
     */
    function screenshot() {
        var link = document.createElement("a");
        link.setAttribute('download', 'lorem-ipsum.png');
        link.setAttribute('href', canvasRef.current.toDataURL("image/png").replace("image/png", "image/octet-stream"));
        link.click();
    }

    return (
        <div className="sb-configurator__viewer">
            <button className='sb-viewer__screenshot-btn' onClick={screenshot}>Save</button>
            <Canvas ref={canvasRef} camera={{ position: [0, 1, 2] }} gl={{ preserveDrawingBuffer: true, logarithmicDepthBuffer: true }}>
                <ambientLight />
                <pointLight  position={[5, 6, 1]} />
                <OrbitControls target={[0, 1, 0]} dragToLook={true} />
                {
                    Object.entries(models).map(([key, model]) => (<ProductModel {...model} key={key} >
                    </ProductModel>))

                }
                <Backdrop
                    position={[0, 0, 0]}
                    scale={[2, 2, 1]}
                    floor={0.25} // Stretches the floor segment, 0.25 by default
                    segments={40} // Mesh-resolution, 20 by default
                >
                    <meshStandardMaterial color="#cccccc" />
                </Backdrop>
                {(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && <axesHelper args={[10]} />}
            </Canvas >
        </div>
    )
}


export default Viewer;