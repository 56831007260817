import DecalEditor from "../components/DecalEditor";
function Editor(props) {
    return (
        <div className="sb-configurator__editor">
            <DecalEditor></DecalEditor>
        </div>
    )

}

export default Editor;