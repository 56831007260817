import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    models: {
        "reference-human": {
            src: "/assets/models/reference-human.glb",
            decals: [
                "neck",
                "bottom"
            ]
        }
    },
    selectedModel: "reference-human",
}

/**
 * Slice for image editing related 
 */
export const productsSlice = createSlice({
    name: "productsData",
    initialState,
    reducers: {
        // Signal update on crop changes
        addProductModel: (state, payload) => {
            // TODO: IMPLEMENT
        }
    },
})

export const { } = productsSlice.actions;
export default productsSlice.reducer;